<template>
  <div>
    <procedure-detail-header
      v-if="loggedUser.role === 'professional'"
      no-survey-creation-button
      show-reload-resource
      :loading-reload-resource="loading.reloadData"
      back-button-text="Indietro"
      :back-button-route="calculateBackRoute"
      @clickCreateFolder="isShow.folder_create=true"
      @clickCreateDocument="isShow.doc_create=true"
      @clickCreateSurvey="isShow.srv_create=true"
      @clickReloadResource="reloadData"
    />

    <procedure-card
      no-survey-icon
      :procedure-obj="procedureData"
      :procedure-enter-btn="false"
      :redirect-to-detail="true"
    />

    <v-divider></v-divider>

    <!-- BREADCRUMB -->
    <v-row class="mt-0">
      <v-col cols="12">
        <v-breadcrumbs
          class="pl-0"
          large
          :items="calculateBreadcrumb"
          divider=">"
        >
        </v-breadcrumbs>
      </v-col>
    </v-row>

    <!-- FOLDERS -->
    <v-row class="mt-3">
      <v-col
        v-for="(folder,idx) in currentLevelFolders"
        :key="'F'+idx"
        cols="12"
        sm="12"
        md="3"
      >
        <folder-card
          folder-route="procedureFolderView"
          :folder-obj="folder"
          :folder-permissions="folder.folder_permissions"
          :go-to-folder-target-id="folder.procedure_id"
          :api-target-id="folder.procedure_id"
          :api-routes="apiRoutes"
          @reloadData="reloadData"
          @droppedItem="setIsDropped"
        />
      </v-col>
    </v-row>

    <!--- DOCUMENTS -->
    <v-row>
      <v-col
        v-for="(doc,idx) in currentLevelDocuments"
        :key="'D'+idx"
        cols="12"
        sm="12"
        md="4"
      >
        <document-card
          is-draggable
          :procedure-id="procedureData.id"
          :document-obj="doc"
          :is-dropped="isDropped[doc.id]"
          @reloadData="reloadData"
        />
      </v-col>
    </v-row>

    <create-folder
      v-if="isPageFullyLoaded"
      :is-show="isShow.folder_create"
      :folder-ref="currentFolder.unique_ref"
      @close="isShow.folder_create=false"
      @reload-data="reloadData"
    />
    <create-document
      v-if="isPageFullyLoaded"
      :is-show="isShow.doc_create"
      :folder-id="currentFolder.id"
      @close="isShow.doc_create=false"
      @reload-data="reloadData"
    />
  </div>
</template>

<script>
import ProcedureDetailHeader from '@/components/section-headers/procedure-detail-header.vue'
import ProcedureCard from '@/components/cards/procedure-card.vue'

import FolderCard from '@/components/cards/folder-card.vue'
import CreateFolder from '@/components/modals/create-folder.vue'

import DocumentCard from '@/components/cards/document-card.vue'
import CreateDocument from '@/components/modals/create-document.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    ProcedureDetailHeader,
    ProcedureCard,
    FolderCard,
    CreateFolder,
    CreateDocument,
    DocumentCard,
  },
  data() {
    return {
      isDropped: {},
      isPageFullyLoaded: false,
      isShow: {
        folder_create: false,
        doc_create: false,
        srv_create: false,
      },
      loading: {
        reloadData: false,
      },
      procedureData: {},
      currentFolder: {},
      mapExpirationChip: {
        expired: 'Scaduta',
      },
      apiRoutes: {
        editApiMethod: 'editFolder',
        deleteApiMethod: 'destroyFolder',
        editDocumentApiMethod: 'editDocument',
        permGetApiMethod: 'getFolderPermissions',
        permSetApiMethod: 'setFolderPermissions',
        permEditApiMethod: 'updateFolderPermission',
        permDeleteApiMethod: 'deleteFolderPermission',
      },
    }
  },
  computed: {
    ...mapGetters(['loggedUser', 'procedureList']),
    calculateBackRoute() {
      if (this.procedureData && this.procedureData.id) {
        // if currentFolder is defined, check if it has a parent_id and is not 0
        if (this.currentFolder && this.currentFolder.parent_id && this.currentFolder.parent_id !== 0) {
          // find the parent folder
          const parentFolder = this.procedureData.folders.find(item => item.id === this.currentFolder.parent_id)

          // if it has a parent_id, return the route to the parent folder
          return `/procedure/${this.procedureData.id}/folder/${parentFolder.unique_ref}`
        }

        return `/procedure/${this.procedureData.id}/view`
      }

      return '/procedure'
    },
    calculateBreadcrumb() {
      // create an array of objects containing the nodes: text, disabled, href
      // the first node has always the name of the procedure and it goes to the procedure detail page
      const breadcrumb = [
        {
          text: this.procedureData.title,
          disabled: false,
          to: `/procedure/${this.procedureData.id}/view`,
        },
      ]

      const tempFolders = []
      const findParentFolder = folder => {
        if (this.procedureData && this.procedureData.id) {
          const parentFolder = this.procedureData.folders.find(item => item.id === folder.parent_id)
          if (parentFolder) {
            // if it has a parent_id, return the route to the parent folder
            tempFolders.push({
              text: parentFolder.name,
              disabled: false,
              to: `/procedure/${this.procedureData.id}/folder/${parentFolder.unique_ref}`,
            })
            findParentFolder(parentFolder)
          }
        }
      }

      findParentFolder(this.currentFolder)

      // reverse the array to have the correct order
      tempFolders.reverse()
      breadcrumb.push(...tempFolders)

      // the last node has always the name of the current folder and is disabled because is the current page
      breadcrumb.push({
        text: this.currentFolder.name,
        disabled: true,
      })

      return breadcrumb
    },
    currentLevelFolders() {
      if (this.currentFolder && this.currentFolder.id && this.currentFolder.id !== 0) {
        // if it has a parent_id, return the route to the parent folder
        return this.procedureData.folders.filter(item => item.parent_id === this.currentFolder.id)
      }

      return []
    },
    currentLevelDocuments() {
      if (this.currentFolder && this.currentFolder.id && this.currentFolder.id !== 0) {
        // if it has a parent_id, return the route to the parent folder
        return this.procedureData.documents.filter(item => item.folder_id === this.currentFolder.id)
      }

      return []
    },
  },
  watch: {
    procedureList() {
      this.getData()
    },
    '$route.params.fid': function () {
      this.setCurrentFolder()
    },
  },
  async created() {
    await this.getData()
      .then(() => this.setCurrentFolder())
      .then(() => this.setPageFullyLoaded())
  },
  async mounted() {
    await this.$store.dispatch('setBreadCrumbLevels', this.breadCrumb)
  },
  methods: {
    async reloadData() {
      this.loading.reloadData = true
      setTimeout(async () => {
        try {
          await this.$store.dispatch('getProcedure', this.procedureData.id)
          this.procedureData = await this.procedureList.find(item => item.id === this.procedureData.id)
          this.isDropped = []
        } catch (error) {
          console.log(error)
        }
        this.loading.reloadData = false
      }, 1000)
    },
    async getData() {
      const procedureId = parseInt(this.$route.params.id, 10)
      this.procedureData = await this.procedureList.find(item => item.id === procedureId)
    },
    async setCurrentFolder() {
      if (this.procedureData && this.procedureData.folders) {
        this.currentFolder = await this.procedureData.folders.find(item => item.unique_ref === this.$route.params.fid)
      }
    },
    goToList() {
      this.$router.push({ name: 'procedure' })
    },
    async setPageFullyLoaded() {
      this.isPageFullyLoaded = true
    },
    setIsDropped(documentId) {
      this.$set(this.isDropped, documentId, true)
    },
  },
}
</script>

<style scoped>
  .v-breadcrumbs >>> a {
    color: rgb(143, 137, 152) !important;
  }
</style>
